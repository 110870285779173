    /**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "fomantic-ui-less/semantic.less"

import wrapWithProvider from "./wrap-with-provider"
import wrapWithLayout from "./wrap-with-layout"

export const wrapRootElement = wrapWithProvider
export const wrapPageElement  = wrapWithLayout
