import axios, {AxiosResponse} from 'axios'
import {WorkoutSpec} from "../data/WorkoutSpec";



export interface YoutubeWorkoutResult {
  workouts: WorkoutSpec[]
}


export async function getWorkouts(): Promise<YoutubeWorkoutResult> {
  let workout_config = {}
  let request_body = {
    'type': 'get_static_workouts',
    'details': workout_config}

  let apiResponse: AxiosResponse<WorkoutSpec[]>


  try {
    let isDevApi = process.env['GATSBY_API_DEV']
    let endpoint = (isDevApi != null && isDevApi == 'true')
        ? '/static'
        : 'https://api.jumpycat.app/static'

    apiResponse = await axios.post<WorkoutSpec[]>(
        endpoint,
        JSON.stringify(request_body),
        {headers: {'Content-Type': 'application/json'}})

    return {
      workouts: apiResponse.data
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

//youtube_id = "6xjrKYOC9LA"
//description = "Workout Structure\n- 2 Groups of 8 Intervals\n- Group pattern ABABABAB\n- 4 Reps per exercise\n- 40 Seconds Active, 20 seconds Rest\n\nEquipment\n- No equipment, bodyweight only\n- Optional: exercise mat\n\nWarm Up - 30 Second Intervals\n- Hip hikes\n- Side leg raises\n- Squat swipes\n- Toe tap lunges\n\nGroup 1\n- Circle toe taps\n- Bridge march\n- Circle toe taps\n- Bridge march\n- Circle toe taps\n- Bridge march\n- Circle toe taps\n- Bridge march\n\nGroup 2\n- Plank hip dips\n- Tabletop bridge holds\n- Plank hip dips\n- Tabletop bridge holds\n- Plank hip dips\n- Tabletop bridge holds\n- Plank hip dips\n- Tabletop bridge holds\n\nCooldown - 30 Second Intervals\n- Wide lever stretch\n- Bentover hip circles\n- Upward dog pose\n- Knee circles"
//difficulty = "sweaty"
//duration = "20"
//reps = "balanced"
//style = "power"
//target = "core"
