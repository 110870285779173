import { combineReducers } from '@reduxjs/toolkit'

import workoutsReducer from './WorkoutSlice'


const rootReducer = combineReducers({
  workouts: workoutsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
