import { configureStore, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'

import rootReducer, { RootState } from './rootReducer'

export const createStore = ()=>{
  return configureStore({
    reducer: rootReducer
  })
}

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

