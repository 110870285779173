/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Navigation} from "./navigation";
import {Container} from "semantic-ui-react";
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

interface Props {
    children?: any
}

const Layout = ({ children } : Props) => {
    let location = useLocation()
  return (
      <Container>
          <Navigation/>
          <br/>
          <main>{children}</main>
          <CookieConsent
              location={"bottom"}
              buttonText={"Accept"}
              buttonStyle={{ background: "green", color:"white"}}
              enableDeclineButton
              declineButtonText={"Reject"}
              declineButtonStyle={{ background: "red", color:"white"}}
              setDeclineCookie={false}
              onAccept={() => {
                  initializeAndTrack(location)
              }}
              cookieName="gatsby-gdpr-google-analytics">
              <HeaderSubHeader>This site uses cookies</HeaderSubHeader>
              <p>We use cookies to personalise content and ads, to provide
                  social media features and to analyse our traffic</p>
          </CookieConsent>
          <footer style={{
            marginTop: `2rem`
          }}>© {new Date().getFullYear()}
          {` `}
          <a href="https://www.jumpycat.app">JumpyCat</a>
        </footer>
      </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
