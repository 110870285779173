import React from 'react';
import {Header, Menu} from 'semantic-ui-react';

export const Navigation = () => {
    return (
        <Header>
            <Menu pointing secondary>
                <Menu.Item header
                           name='Get the app'
                           href="https://jumpycatapp.com/">
                    Download app
                </Menu.Item>
                <Menu.Item header
                           name='Blog'
                           href="https://blog.jumpycatapp.com/">
                    Blog
                </Menu.Item>
            </Menu>
        </Header>
    )
}
