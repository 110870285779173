import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { WorkoutSpec} from "../data/WorkoutSpec";
import { AppThunk } from './store'
import {WorkoutListFilter} from "../components/workouts/workoutList";
import {getWorkouts} from "../api/JumpyAPI";

interface WorkoutsState {
  workouts: WorkoutSpec[]
  allWorkouts: WorkoutSpec[]
  isLoading: boolean
  error: string | null
}

const workoutsInitialState: WorkoutsState = {
  workouts: [],
  allWorkouts: [],
  isLoading: false,
  error: null
}

function startLoading(state: WorkoutsState) {
  state.isLoading = true
}

function loadingFailed(state: WorkoutsState, action: PayloadAction<string>) {
  state.isLoading = false
  state.error = action.payload
}

const workouts = createSlice({
  name: 'workouts',
  initialState: workoutsInitialState,
  reducers: {
    getWorkoutsStart: startLoading,
    getWorkoutsSuccess(state, payload: PayloadAction<WorkoutSpec[]>) {
      state.allWorkouts = payload.payload
      state.workouts = payload.payload
      state.isLoading = false
      state.error = null
    },
    actionFilterWorkouts(state, payload: PayloadAction<WorkoutListFilter>) {
      state.workouts = _filterWorkouts(state.allWorkouts, payload.payload)
      state.isLoading = false
      state.error = null
    },
    getWorkoutsFailure: loadingFailed
  }
})

export const {
  getWorkoutsStart,
  getWorkoutsSuccess,
  getWorkoutsFailure,
  actionFilterWorkouts
} = workouts.actions

export default workouts.reducer


export const fetchWorkouts = (
    filterSpec: WorkoutListFilter
): AppThunk => async dispatch => {
  try {
    dispatch(getWorkoutsStart())
    const workouts = await getWorkouts()
    dispatch(getWorkoutsSuccess(_filterWorkouts(workouts.workouts, filterSpec)))
  } catch (err) {
    dispatch(getWorkoutsFailure(err.toString()))
  }
}

export const filterWorkouts = (filterSpec: WorkoutListFilter): AppThunk => {
  return async (dispatch,
                getState) => {

    dispatch(getWorkoutsStart())
    dispatch(actionFilterWorkouts(filterSpec))
  }
}

export const filterWorkoutsTest = createAsyncThunk(
    'filterWorkoutsTest',
    async (filterSpec:WorkoutListFilter, thunkAPI) => {
      thunkAPI.dispatch(getWorkoutsStart())
      //let allWorkouts = thunkAPI.getState().workouts.allWorkouts
      let state = thunkAPI.getState() as {workouts:{ allWorkouts: WorkoutSpec[] }}

      if (state.workouts.allWorkouts.length == 0) {
        const workouts = await getWorkouts()
        thunkAPI.dispatch(getWorkoutsSuccess(_filterWorkouts(workouts.workouts, filterSpec)))
      } else {
        thunkAPI.dispatch(actionFilterWorkouts(filterSpec))
      }
    }
)

function _filterWorkouts(workouts: WorkoutSpec[], filter: WorkoutListFilter) {
  let filteredWorkouts = workouts.filter(workout => {
    return _filterWorkout(workout, filter)
  })

  return filteredWorkouts
}

function _filterWorkout(workout: WorkoutSpec, filter: WorkoutListFilter) {
  let isEqual =  _isEqualToFilter(workout.variety, filter.selectedVariety) &&
      _isEqualToFilter(workout.target, filter.selectedTarget) &&
      _isEqualToFilter(workout.focus, filter.selectedFocus) &&
      _isEqualToFilter(workout.difficulty, filter.selectedDifficulty) &&
      _isEqualToFilter(workout.duration, filter.selectedDuration)

  return isEqual
}

function _isEqualToFilter(currentVal: string, filterVal: string) {
  return filterVal === "" || filterVal === "*" || filterVal === currentVal
}
